import React, { useEffect, useState } from 'react'
import classes from "./new-panel.module.css"
import Img from "../../img/new_img.png"
import axios from 'axios'

const NewPanel = ({id, onClose}) => {
	const [header, setHeader] = useState("")
	const [text, setText] = useState("")
	const [img, setImg] = useState("")
	useEffect(() => {
		const getNew = async () => {
			try {
				const {data} = await axios.get(
					`https://teplye-denki.ru/api/news/get-one/${id}`
				)
				setHeader(data.header)
				setText(data.text)
				setImg(data.headerImg)
			} catch (error) {
				console.log(error);
			}
		}
		getNew()
	}, [])

  return (
		<div className={classes.new_panel}>
			<div className={classes.new}>
                <button className={classes.close_btn} onClick={onClose}>
					<svg
                        className={classes.close}
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='#fff'
						class='bi bi-x-lg'
						viewBox='0 0 16 16'
					>
						<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
					</svg>
                </button>
				<img src={`https://teplye-denki.ru${img}`} alt='' width={'100%'} height={"320px"} className={classes.img}/>
				<p className={classes.header}>{header}</p>
				<p className={classes.text}>
					{text}
				</p>
			</div>
		</div>
	)
}

export default NewPanel