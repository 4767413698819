import React from 'react'
import classes from "../steps/steps.module.css"

const Steps = ({text, step}) => {
  return (
		<div className={classes.steps}>
			<div className={classes.step}>Шаг {step}</div>
			{text}
		</div>
	)
}

export default Steps