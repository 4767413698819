import React from 'react'
import Card from '../card/Card'
import classes from "./cards.module.css"
import Phones from '../phones/Phones'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

const Cards = () => {
	const [showPanel, setShowPanel] = useState(false)
	const [cards, setCards] = useState([])

	useEffect(() => {
		const getAllCatalogs = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/catalog/get-all'
				)
				setCards(data)
			} catch (error) {
				console.log(error)
			}
		}
		getAllCatalogs()
	}, [])

  return (
		<>
			{showPanel && <Phones onClose={() => setShowPanel(false)}/>}
			<div className={classes.cards}>
				{
					cards.map(el => {
						return (
							<Card
								onOpen={() => setShowPanel(true)}
								header={el.header}
								img={el.headerImg}
								price={el.price}
								address={el.address}
							/>
						)
					})
				}
			</div>
		</>
	)
}

export default Cards