import React from 'react'
import classes from "./card.module.css"
import CardImg from "../../img/card-img.png"

const Card = ({onOpen, header, img, price, address}) => {
  return (
		<div className={classes.card} onClick={onOpen}>
			<div className={classes.card_img}>
				<img
					src={`https://teplye-denki.ru${img}`}
					alt='card img'
					width={'100%'}
				/>
			</div>
			<div className={classes.card_info}>
				<p className={classes.header}>{header}</p>
				<p className={classes.address}>{address}</p>
			</div>
			<div>
				<div>
					<div></div>
				</div>
				<div></div>
			</div>
			<div className={classes.footer}>
				<p className={classes.price_panel}>
					от <span className={classes.price}>{price} ₽</span>/сут
				</p>
				{onOpen ? <button className={classes.btn}> Узнать о наличии мест</button> : null}
			</div>
		</div>
	)
}

export default Card