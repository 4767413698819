import React from 'react'
import classes from "./navbar.module.css"
import { Link } from 'react-router-dom'

const NavBar = ({
	scrollToServices,
	scrollToPrices,
	scrollToContacts,
	scrollToGallery,
	scrollToHouses,
}) => {
	return (
		<div className={classes.navbar}>
			<Link
				className={classes.link}
				to={'/'}
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: 'smooth', // Smooth scrolling effect
					})
				}}
			>
				Главная
			</Link>
			<Link
				className={classes.link}
				to={'/about'}
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: 'smooth', // Smooth scrolling effect
					})
				}}
			>
				О нас
			</Link>
			<Link className={classes.link} onClick={scrollToHouses}>
				Пансионаты
			</Link>
			<Link className={classes.link} onClick={scrollToHouses}>
				Дома отдыха
			</Link>
			<Link className={classes.link} onClick={scrollToServices}>
				Услуги
			</Link>
			<Link className={classes.link} onClick={scrollToPrices}>
				Цены
			</Link>
			<Link className={classes.link} onClick={scrollToGallery}>
				Фотогалерея
			</Link>
			<Link className={classes.link} onClick={scrollToContacts}>
				Контакты
			</Link>
		</div>
	)
}

export default NavBar