import React, { useState } from 'react'
import classes from "./header.module.css"
import {ReactComponent as Email} from "../../icons/svg.svg"
import {ReactComponent as WhatsApp} from "../../icons/svg-1.svg"
import Application from '../application/Application'

const Header = ({onOpen}) => {
	const [show, setShow] = useState(false)
  return (
		<>
			{show && <Application onClose={() => setShow(false)} />}
			<header>
				<div>
					<a href='tel:+74465848'>
						<p className={classes.text}>+7 (916) 549-77-77</p>
					</a>
					<p className={classes.text_b}>Ежедневно с 9-00 до 21-00</p>
				</div>
				<div>
					<p className={classes.text}>
						<a href='mailto:dasd' className={classes.text}>
							<Email /> Написать директору
						</a>
					</p>
					<p className={classes.text_b}>
						<a
							href='https://wa.me/+37494673735?text='
							className={classes.text_b}
						>
							<WhatsApp /> Whatsapp
						</a>
					</p>
				</div>
				<button className={classes.btn} onClick={() => setShow(true)}>
					Оставить заявку
				</button>
				<div className={classes.menu_p}>
					<a href='tel:+79165497777'>
						<p>+7 (916) 549-xx-xx</p>
					</a>
					<div onClick={onOpen} className={classes.open}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='33'
							height='33'
							fill='#23B576'
							class='bi bi-list'
							viewBox='0 0 16 16'
						>
							<path
								fill-rule='evenodd'
								d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5'
							/>
						</svg>
					</div>
				</div>
			</header>
		</>
	)
}

export default Header