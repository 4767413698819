import React from 'react'
import classes from "./offcanvas.module.css"
import { ReactComponent as Email } from '../../icons/svg.svg'
import { ReactComponent as WhatsApp } from '../../icons/svg-1.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as Help } from '../../icons/help.svg'

const Offcanvas = ({onClose, isOpened, scrollToHouses, scrollToServices, scrollToPrices, scrollToContacts, 	scrollToGallery, }) => {
  return (
		<>
			<div
				className={classes.back}
				style={isOpened ? { width: '100%' } : { display: 'none' }}
			></div>
			<div
				className={classes.offcanvas}
				style={isOpened ? { width: '100%' } : { width: '0px' }}
			>
				<div className={classes.close} onClick={onClose}>
					<svg
						style={isOpened ? { width: '100%' } : { display: 'none' }}
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='#23B576'
						class='bi bi-x-lg'
						viewBox='0 0 16 16'
					>
						<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
					</svg>
				</div>
				<div
					className={classes.data}
					style={isOpened ? { width: '100%' } : { display: 'none' }}
				>
					<div>
						<a href='tel:+4587457'>
							<p className={classes.text} style={{ color: '#23B576' }}>
								+7 (916) 549-77-77
							</p>
						</a>
						<p className={classes.text}>Ежедневно с 9-00 до 21-00</p>
					</div>
					<div>
						<p className={classes.text}>
							<a href='mailto:adsdas' className={classes.text}>
								<Email /> Написать директору
							</a>
						</p>
						<p className={classes.text}>
							<a
								href='https://wa.me/+37494673735?text='
								className={classes.text}
							>
								<WhatsApp /> Whatsapp
							</a>
						</p>
					</div>
					<div className={classes.links}>
						<Link className={classes.link} to={'/about'} onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: 'smooth', // Smooth scrolling effect
							})
							onClose()
						}}>
							О нас
						</Link>
						<Link className={classes.link} onClick={() => {
							scrollToHouses()
							onClose()
						}}>Пансионаты</Link>
						<Link
							className={classes.link}
							onClick={() => {
								scrollToServices()
								onClose()
							}}
						>
							Услуги
						</Link>
						<Link
							className={classes.link}
							onClick={() => {
								scrollToPrices()
								onClose()
							}}
						>
							Цены
						</Link>
						<Link className={classes.link} onClick={() => {
								scrollToGallery()
								onClose()
						}}>Фотогалерея</Link>
						<Link
							className={classes.link}
							onClick={() => {
								scrollToContacts()
								onClose()
							}}
						>
							Контакты
						</Link>
						<a
							href='https://pay.cryptocloud.plus/pos/ITqkSrLKWOFDP9aU'
							target='_blank'
						>
							<button className={classes.btn}>
								<Help /> Пожертвовать
							</button>
						</a>
					</div>
				</div>
			</div>
		</>
	)
}

export default Offcanvas