import React from 'react'
import classes from "./price.module.css"
import {ReactComponent as Check} from "../../icons/check.svg"

const Price = ({header, type, pluses}) => {
  return (
		<div className={classes.price}>
			<div>
				<div className={classes.header}>
					<p className={classes.header_text}>{header}</p>
					<p className={classes.type}>{type}</p>
				</div>
				<div>
					<p className={classes.tarif}>Тариф «Базовый», а также:</p>
					<div className={classes.pluses}>
						{pluses.map((el, index) => {
							return (
								<div key={index} className={classes.plus}>
									<Check /> {el}
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<p className={classes.price_panel}>
					от <span className={classes.price_num}>1 299 ₽ </span>/сут
				</p>
				<div className={classes.btn_p}>
					<button className={classes.btn}>Подробнее</button>
				</div>
			</div>
		</div>
	)
}

export default Price