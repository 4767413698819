import React from 'react'
import classes from "./home-wrapper.module.css"
import {ReactComponent as Help} from "../../icons/help.svg"

const HomeWrapper = ({ scrollToTarget }) => {
	return (
		<div className={classes.wrapper}>
			<a href='https://pay.cryptocloud.plus/pos/ITqkSrLKWOFDP9aU' target='_blank'>
				<button className={classes.btn}>
					<Help /> Пожертвовать
				</button>
			</a>
			<div className={classes.info}>
				<div>
					<p className={classes.header}>
						Пансионаты для пожилых и лежачих больных.
					</p>
					<p className={classes.header}>Досмотр за лежачими больными.</p>
				</div>
				<div style={{ marginTop: '68px' }} className={classes.panel}>
					<div className={classes.box}>
						<div className={classes.blocks}>
							<div className={classes.block}>Понимаем</div>
							<div className={classes.block}>Заботимся</div>
							<div className={classes.block}>Оберегаем</div>
						</div>
						<div className={classes.texts}>
							<div>
								<p>индивидуальные условия ухода за больным</p>
							</div>
							<div>
								<p>профилактика пролежней</p>
							</div>
							<div>
								<p>24/7 присмотр</p>
							</div>
						</div>
					</div>
				</div>
				<button className={classes.view} onClick={scrollToTarget}>
					Показать пансионаты
				</button>
			</div>
		</div>
	)
}

export default HomeWrapper