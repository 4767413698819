import React, { useEffect, useState } from 'react'
import classes from "./point.module.css"

const Point = ({ id, deletePoint, type, onSave }) => {

    const [text, setText] = useState("")

	return (
		<div className={classes.p}>
			<span>{id}</span>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				fill='green'
				class='bi bi-geo-alt-fill'
				viewBox='0 0 16 16'
			>
				<path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6' />
			</svg>
			<svg
				onClick={() => deletePoint(id)}
				className={classes.closes}
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				fill='red'
				class='bi bi-x-lg'
				viewBox='0 0 16 16'
			>
				<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
			</svg>
			{type ? (
				<>
					<textarea type='text' placeholder='Напишите текст' value={text} onChange={e => setText(e.target.value)}/>
					<button className={classes.save} onClick={() => onSave(text)}>
						Сохранить
					</button>
				</>
			) : null}
		</div>
	)
}

export default Point