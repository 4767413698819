import React, { useEffect, useState } from 'react'
import classes from "./home-info.module.css"
import map from "../../img/map.png"
import {ReactComponent as Arrow} from "../../arrow.svg"
import axios from 'axios'

const HomeInfo = () => {
	const [showMap, setShowMap] = useState(false)
	const [points, setPoints] = useState([])

	useEffect(() => {
		const getAllPoints = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/points/get-all'
				)
				setPoints(data)
			} catch (error) {
				console.log(error);
			}
		}
		getAllPoints()
	}, [])

	const handleClick = event => {
		const rect = event.currentTarget.getBoundingClientRect() // Получаем размеры и позицию дива
		const left = event.clientX - rect.left // X относительно дива
		const top = event.clientY - rect.top // Y относительно дива

		console.log(`Координаты клика внутри дива: left: ${left}px, top: ${top}px`)
		setPoints([...points, {id: Math.random(), left: left, top: top}])
	}
  return (
		<div className={classes.info}>
			<div>
				<h2 className={classes.header}>
					Пансионаты для пожилых людей{' '}
					<span style={{ color: '#23B576' }}>«Теплые Деньки»</span>
				</h2>
				<p className={classes.text}>
					«Теплые Деньки» — пансионаты для пожилых людей в Москве с
					круглосуточным присмотром и комплексным уходом.
				</p>
				<p className={classes.text}>
					Не получается обеспечить близкому человеку специализированный уход,
					круглосуточный присмотр и бытовое сопровождение в домашних условиях?
					«Тёплые Беседы» помогут создать для него комфорт в любом из наших
					частных пансионатов в Москве и по области.
				</p>
				<p className={classes.text}>
					Мы помогаем поддерживать здоровый режим дня, проводить гигиенические
					процедуры своевременно и без стресса, питаться полноценно и вкусно.
					<span style={{ color: '#23B576' }}>
						Проводим необходимую реабилитацию, работа с психологом.
					</span>
				</p>
				<div className={classes.arr_info}>
					<span>
						Какие услуги в частных пансионатах для престарелых в Москве?
					</span>
					<button>
						<Arrow />
					</button>
				</div>
				<div className={classes.arr_info} style={{ borderTop: 'none' }}>
					<span>Медицинское обслуживание в частных домах престарелых</span>
					<button>
						<Arrow />
					</button>
				</div>
			</div>
		</div>
	)
}

export default HomeInfo