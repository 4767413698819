import React, { forwardRef, useEffect, useState } from 'react'
import classes from "./services.module.css"
import Service from '../service/Service'
import axios from 'axios';

const Services = forwardRef((props, ref) => {

	const [services, setServices] = useState([])

	useEffect(() => {
		const getAllServices = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/services/get-all'
				)
				setServices(data)
			} catch (error) {
				console.log(error)
			}
		}

		getAllServices()
	}, [])

  return (
		<div ref={ref}>
			<p className={classes.header}>Предоставляем услуги</p>
			<div className={classes.services}>
				{services.map(el => {
					return (
							<Service header={el.header} text={el.text} img={el.headerImg} />
					)
				})}
			</div>
			<button className={classes.more} style={{ marginTop: '25px' }}>
				Все услуги
			</button>
		</div>
	)
});

export default Services