import React, { useRef, useState } from 'react'
import Header from './components/header/Header'
import NavBar from './components/navbar/NavBar'
import Footer from './components/footer/Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import Offcanvas from './components/offcanvas/Offcanvas'
import Admin from './pages/admin/Admin'

function App() {
	const housesRef = useRef(null)
	const servicesRef = useRef(null)
	const pricesRef = useRef(null)
	const contactsRef = useRef(null)
	const galleryRef= useRef(null)
	const targetRef = useRef(null)

	const [onOpenCanvas, setOnOpenCanvas] = useState(false)

	return (
		<>
			<Router>
				<Offcanvas
					scrollToGallery={() => {
						galleryRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
							inline: 'nearest',
						})
					}}
					scrollToServices={() => {
						servicesRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
							inline: 'nearest',
						})
					}}
					scrollToPrices={() => {
						pricesRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
							inline: 'nearest',
						})
					}}
					scrollToContacts={() => {
						contactsRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
							inline: 'nearest',
						})
					}}
					scrollToHouses={() => {
						housesRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
							inline: 'nearest',
						})
					}}
					onClose={() => setOnOpenCanvas(false)}
					isOpened={onOpenCanvas}
				/>
				<div className='page-header'>
					<Header onOpen={() => setOnOpenCanvas(true)} />
					<NavBar
						scrollToGallery={() => {
							galleryRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'nearest',
							})
						}}
						scrollToHouses={() => {
							housesRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'start',
								inline: 'nearest',
							})
						}}
						scrollToServices={() => {
							servicesRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'nearest',
							})
						}}
						scrollToPrices={() => {
							pricesRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'nearest',
							})
						}}
						scrollToContacts={() => {
							contactsRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'nearest',
							})
						}}
					/>
				</div>
				<Routes>
					<Route
						path='/*'
						element={
							<Home
								galleryTarget={galleryRef}
								servicesTarget={servicesRef}
								pricesTarget={pricesRef}
								contactsTarget={contactsRef}
								housesRef={housesRef}
							/>
						}
					/>
					<Route path='/panelwork' element={<Admin />} />
				</Routes>
				<Footer />
			</Router>
		</>
	)
}

export default App
