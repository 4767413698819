import React, { useState } from 'react'
import classes from "./application.module.css"
import axios from 'axios'

const Application = ({onClose}) => {
  const [isGood, setIsGood] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post('https://teplye-denki.ru/api/applications/create', {
				name,
				phone,
				message
			})
      if(data.success) {
        setIsGood(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
		<div className={classes.page}>
			<div className={classes.inpts}>
				<button className={classes.close_btn} onClick={onClose}>
					<svg
						className={classes.close}
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='#23B576'
						class='bi bi-x-lg'
						viewBox='0 0 16 16'
					>
						<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
					</svg>
				</button>
				<form
					className={classes.form}
					onSubmit={evn => {
						evn.preventDefault()
						handleSubmit()
					}}
				>
					<p className={classes.header}>Оставить заявку</p>
					<input
						type='text'
						required
						onChange={evn => setName(evn.target.value)}
						placeholder='Как к Вам обращаться ?'
					/>
					<input
						type='text'
						required
						onChange={evn => setPhone(evn.target.value)}
						placeholder='Ваш номер телефона'
					/>
					<textarea name="" id="" placeholder='Сообщение' onChange={e => setMessage(e.target.value)}></textarea>
					<button type='submit'>Отправить</button>
					{isGood && <p className={classes.good}>Ваша заявка отправлена !</p>}
				</form>
			</div>
		</div>
	)
}

export default Application