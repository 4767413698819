import React, { useEffect, useState } from 'react'
import classes from "./news.module.css"
import New from '../new/New'
import axios from 'axios'

const News = ({openNew}) => {
	const [news, setNews] = useState([])
	useEffect(() => {
		const getAllNews = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/news/get-all'
				)
				setNews(data)
			} catch (error) {
				console.log(error)
			}
		}
		getAllNews()
	}, [])
  return (
		<div className={classes.news}>
			<p className={classes.header}>Новости</p>
			<div className={classes.n}>
				{news.map(el => {
					return (
						<div onClick={() => openNew(el.id)}>
							<New
								key={el.id}
								header={el.header}
								image={el.headerImg}
								date={'10.05.2024'}
							/>
						</div>
					)
				})}
			</div>
			{/* <div className={classes.pagination}>
				<div
					className={classes.cr}
					style={{ background: '#23B576', opacity: '100%' }}
				></div>
				<div className={classes.cr}></div>
				<div className={classes.cr}></div>
				<div className={classes.cr}></div>
			</div> */}
			<button className={classes.more}>Все новости</button>
		</div>
	)
}

export default News