import React from 'react'
import classes from "./phones.module.css"
import { ReactComponent as Email } from '../../icons/svg.svg'
import { ReactComponent as WhatsApp } from '../../icons/svg-1.svg'

const Phones = ({onClose}) => {
  return (
		<div className={classes.phones}>
			<div className={classes.panel}>
				<button className={classes.close_btn} onClick={onClose}>
					<svg
						className={classes.close}
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='#23B576'
						class='bi bi-x-lg'
						viewBox='0 0 16 16'
					>
						<path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
					</svg>
				</button>
				<div>
					<span className={classes.header}>
						Чтобы узнать о наличии мест свяжитесь с оператором
					</span>
				</div>
				<div>
					<a href='tel:+79165497777'>
						<p>+79165497777</p>
					</a>
					<a href='tel:+79165497777'>
						<p>+79165497777</p>
					</a>
				</div>
				<div>
					<div>
						<p className={classes.text}>
							<a href='mailto:asdasd' className={classes.text}>
								<Email /> Написать директору
							</a>
						</p>
						<p className={classes.text_b}>
							<a
								href='https://wa.me/+37494673735?text='
								className={classes.text_b}
							>
								<WhatsApp /> Whatsapp
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Phones