import React from 'react'
import classes from "./home-questions.module.css"

const HomeQuestions = () => {
  return (
		<div className={classes.questions}>
			<h2 className={classes.header}>Как нам доверить близкого человека</h2>
			<p className={classes.text}>
				Мы оказываем круглосуточный уход за пожилыми в любом возрасте, с любым
				физическим состоянием и всеми силами стремимся активизировать человека,
				насколько это возможно. Если вы подыскиваете платный пансионат для
				пожилых в Москве или поблизости от дома, обратитесь к нам. Мы поможем
				сориентироваться и выбрать наиболее подходящий по вашим критериям
				вариант.
			</p>
		</div>
	)
}

export default HomeQuestions