import React, { forwardRef } from 'react'
import classes from "./contact.module.css"

const Contact = forwardRef((props, ref) => {
  return (
		<div ref={ref}>
			<p className={classes.header}>Контакты</p>
			<div className={classes.contacts}>
				<div>
					<p className={classes.header_text}>
						Звоните и пишите — ответим на все ваши вопросы
					</p>
					<div className={classes.texts}>
						<p className={classes.text}>
							С любыми вопросами вы можете обращаться к операторам нашего
							консультационного центра, мы расскажем об услугах, размещении и
							ответим на ваши вопросы.
						</p>
						<p className={classes.text}>
							Если у вас есть сомнения в правильности выбора пансионата,
							расскажите о проблемах возникающих при уходе за вашим близким, мы
							поможем выбрать пансионат специализирующийся на уходе за пожилыми
							именно с вашим случаем.
						</p>
						<p className={classes.text}>
							С такими проблемами может столкнуться каждый. 8 лет мы помогаем
							семьям в трудных жизненных ситуациях, обеспечить достойный уход
							своим близким, а пожилым найти новый круг общения и жить
							полноценной жизнью. Звоните!
						</p>
					</div>
				</div>
				<div>
					<p className={classes.header_text}>
						Приглашаем Вас на онлайн экскурсию в любой день!
					</p>
					<p className={classes.text}>
						В рамках мер обеспечения безопасности от COVID-19 мы предлагаем
						записаться на онлайн экскурсию в любой пансионат нашей сети в
						удобное для вас время. Управляющая проведет онлайн-трансляцию и
						ответит на все вопросы.
					</p>
					<div className={classes.ex}>
						<p className={classes.info}>
							Для этого звоните в удобное время по телефону:
						</p>
						<a href='tel:+79165497777'>
							<p className={classes.phone}>+7 (916) 549-77-77</p>
						</a>
						<p className={classes.ex_time}>
							Экскурсии с 9:00 до 21:00 без выходных
						</p>
					</div>
				</div>
			</div>
		</div>
	)
})

export default Contact