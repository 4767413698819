import React from 'react'
import classes from "./footer.module.css"
import {ReactComponent as Tg} from "../../icons/Link.svg"
import { ReactComponent as YouTube } from '../../icons/Link-2.svg'
import { ReactComponent as Vk } from '../../icons/Link-3.svg'
import { ReactComponent as Dz } from '../../icons/Link-4.svg'

const Footer = () => {
  return (
		<footer>
			<div className={classes.social}>
				<div className={classes.links}>
					<Tg className={classes.soc_link} />
					<YouTube className={classes.soc_link} />
					<Vk className={classes.soc_link} />
					<Dz className={classes.soc_link} />
				</div>
				<div className={classes.blocks}>
					{/* <div className={classes.block}>
						<p className={classes.header}>Документы</p>
						<div
							className={classes.punkt}
							style={{ gridTemplateColumns: '1fr', width: '200px' }}
						>
							<span className={classes.ln}>Соглашение об обработке </span>
						</div>
						<div
							className={classes.punkt}
							style={{ gridTemplateColumns: '1fr', width: '200px' }}
						>
							<span className={classes.ln}>персональных данных</span>
						</div>
						<div
							className={classes.punkt}
							style={{ gridTemplateColumns: '1fr', width: '200px' }}
						>
							<span className={classes.ln}>Cookie-файлы</span>
						</div>
						<div
							className={classes.punkt}
							style={{ gridTemplateColumns: '1fr', width: '200px' }}
						>
							<span className={classes.ln}>Договор</span>
						</div>
						<div
							className={classes.punkt}
							style={{ gridTemplateColumns: '1fr', width: '200px' }}
						>
							<span className={classes.ln}>Карта сайта</span>
						</div>
					</div> */}
					<div className={classes.block}>
						<p className={classes.header}>График работы</p>
						<div className={classes.punkt}>
							<span>Пансионаты:</span>
							<span className={classes.green}>Круглосуточно</span>
						</div>
						<div className={classes.punkt}>
							<span>Колл-центр:</span>
							<span>Ежедневно с 9:00 до 21:00</span>
						</div>
						<div className={classes.punkt}>
							<span>Адрес:</span>
							<a
								href='https://yandex.com/maps/213/moscow/house/ulitsa_kuznetskiy_most_9_10s1/Z04YcAVhTEEEQFtvfXt3c31mZQ==/?indoorLevel=1&ll=37.621543%2C55.762151&z=17.14'
								style={{ color: '#23B576', fontWeight: '600' }}
							>
								<span>ул. Пресненская набережная 10к1, Москва</span>
							</a>
						</div>
						<div className={classes.punkt}>
							<span>Почта:</span>
							<a
								href='mailto:asdf'
								style={{ color: '#23B576', fontWeight: '600' }}
							>
								<span className={classes.green}>info@teplye-besedy.ru</span>
							</a>
						</div>
					</div>
					<div className={classes.block}>
						<p className={classes.header}>Свяжитесь с нами</p>
						<a href='tel:+79165497777'>
							<span className={classes.phone}>+7 (916) 549-77-77</span>
						</a>
					</div>
				</div>
				<p className={classes.text}>
					© 2012 - 2024 г. Официальный сайт сети частных пансионатов для пожилых
					"Теплые Деньки". Представленная на сайте информация не является
					публичной офертой.
				</p>
			</div>
		</footer>
	)
}

export default Footer