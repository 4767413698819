import React from 'react'
import classes from "./service.module.css"

const Service = ({header, text, img}) => {
  return (
		<div className={classes.service}>
			<div>
				<p className={classes.header}>{`${header} ➔`}</p>
				<span className={classes.text}>{text}</span>
			</div>
			<img
				src={`https://teplye-denki.ru${img}`}
				alt='Service img'
				className={classes.service_img}
			/>
		</div>
	)
}

export default Service