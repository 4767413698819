import React, { forwardRef, useEffect, useState} from 'react'
import classes from './gallery.module.css'
import axios from 'axios'

const Gallery = forwardRef((props, ref) => {
	const [gallery, setGallery] = useState([])
	useEffect(() => {
		const getGallery = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/gallery/get-all'
				)
				setGallery(data)
			} catch (error) {
				console.log(error)
			}
		}
		getGallery()
	}, [])
	return (
		<div ref={ref}>
			<p className={classes.header}>Фотогалерея</p>
			<div className={classes.gallery}>
				{gallery.map(el => {
					return (
						<img
							src={`https://teplye-denki.ru${el.img}`}
							alt='Gallery image'
							width={'100%'}
						/>
					)
				})}
			</div>
		</div>
	)
})

export default Gallery
