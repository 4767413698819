import React, { useEffect, useState } from 'react'
import classes from "./admin.module.css"
import axios from 'axios'
import map from "../../img/map.png"
import New from '../../components/new/New'
import 'react-toastify/dist/ReactToastify.css'
import Service from '../../components/service/Service'
import Card from '../../components/card/Card'
import Point from '../../components/point/Point'

const Admin = () => {

    const [logined, setLogined] = useState(false)
    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")

	const [header, setHeader] = useState("")
	const [text, setText] = useState("")
	const [img, setImg] = useState(null)

	const [headerService, setHeaderService] = useState('')
	const [textService, setTextService] = useState('')
	const [imgService, setImgService] = useState(null)

	const [headerCatalog, setHeaderCatalog] = useState('')
	const [price, setPrice] = useState('')
	const [address, setAddress] = useState("")
	const [imgCatalog, setImgCatalog] = useState(null)

	const [points, setPoints] = useState([])
	const [news, setNews] = useState([])
	const [gallery, setGallery] = useState([])
	const [galleryImages, setGalleryImages] = useState([])
	const [services, setServices] = useState([])
	const [catalog, setCatalog] = useState([])
	const [apps, setApps] = useState([])
	const [big, setBig] = useState(false)
	const [showArea, setShowArea] = useState(false)
	const [showArea2, setShowArea2] = useState(false)
	const [showArea3, setShowArea3] = useState(false)

	const [newPoints, setNewPoints] = useState([])

	useEffect(() => {
		const storedToken = localStorage.getItem('authToken')
		const fetchProtectedData = async () => {
			try {
				const response = await axios.get(
					'https://teplye-denki.ru/api/auth/protected',
					{
						headers: { Authorization: `Bearer ${storedToken}` },
					}
				)
				setLogined(true)
			} catch (error) {
				
			}
		}
		fetchProtectedData()
	}, [])

	const addPoint = async (left, top, text, id) => {
		try {
			const { data } = await axios.post(
				'https://teplye-denki.ru/api/points/create', {
					left : parseInt(left),
					top : parseInt(top),
					text
				}
			)
			setNewPoints(newPoints.filter(e => e.id !== id))
			setPoints([
				...points,
				{
					id: id,
					left: left,
					top: top,
					text: text
				},
			])
		} catch (error) {
			console.log(error)
		}
	}
	const addImage = async () => {
		try {
			galleryImages.forEach(el => {
			const formData = new FormData()
			formData.append('img', el)
			const { data } = axios.post(
				'https://teplye-denki.ru/api/gallery/create/',																						
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			})
		} catch (error) {
			console.log(error);
		} 
	}
	const addNew = async () => {
		try {
			const formData = new FormData()
			formData.append('img', img)
			formData.append('header', header)
			formData.append('text', text)
			const { data } = await axios.post(
				'https://teplye-denki.ru/api/news/create',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			window.location.reload()
			
		} catch (error) {
			console.log(error)
		}
	}
	const addService = async () => {
		try {
			const formData = new FormData()
			formData.append('img', imgService)
			formData.append('header', headerService)
			formData.append('text', textService)
			const { data } = await axios.post(
				'https://teplye-denki.ru/api/services/create',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			window.location.reload()
		} catch (error) {
			console.log(error)
		}
	}
	const addCatalog = async () => {
		try {
			const formData = new FormData()
			formData.append('img', imgCatalog)
			formData.append('header', headerCatalog)
			formData.append('price', price)
			formData.append('address', address)
			const { data } = await axios.post(
				'https://teplye-denki.ru/api/catalog/create',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			window.location.reload()
		} catch (error) {
			console.log(error)
		}
	}
	const deleteImage = async (id) => {
		try {
			const { data } = await axios.delete(
				`https://teplye-denki.ru/api/gallery/delete/${id}`
			)
			setGallery(gallery.filter(el => el.id !== id))
		} catch (error) {
			console.log(error)
		}
	}
	const deleteNew = async (id) => {
		try {
			const { data } = await axios.delete(																																																																				
				`https://teplye-denki.ru/api/news/delete/${id}`
			)
			setNews(news.filter(el => el.id !== id))
		} catch (error) {
			console.log(error);
		}
	}
	const deleteService= async id => {
		try {
			const { data } = await axios.delete(
				`https://teplye-denki.ru/api/services/delete/${id}`
			)
			setServices(services.filter(el => el.id !== id))
		} catch (error) {
			console.log(error)
		}
	}
	const deleteCatalog = async id => {
		try {
			const { data } = await axios.delete(
				`https://teplye-denki.ru/api/catalog/delete/${id}`
			)
			setCatalog(catalog.filter(el => el.id !== id))
		} catch (error) {
			console.log(error)
		}
	}
	const deletePoint = async (id, isNew) => {
		try {
			if(isNew) {
				setNewPoints(newPoints.filter(el => el.id !== id))
			}else {
				const { data } = await axios.delete(
					`https://teplye-denki.ru/api/points/delete/${id}`
				)
				setPoints(points.filter(el => el.id !== id))
			}
		} catch (error) {
			console.log(error);
		}
	}
	const deleteApp = async (id) => {
		try {
			const { data } = await axios.delete(
				`https://teplye-denki.ru/api/applications/delete/${id}`
			)
			setApps(apps.filter(el => el.id !== id))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		const getAllApps= async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/applications/get-all'
				)
				setApps(data)
			} catch (error) {
				console.log(error)
			}
		}
		const getAllNews = async () => {
			try {
				const { data } = await axios.get('https://teplye-denki.ru/api/news/get-all')
				setNews(data)
			} catch (error) {
				console.log(error);
			}
		}
		const getAllServices = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/services/get-all'
				)
				setServices(data)
			} catch (error) {
				console.log(error)
			}
		}
		const getAllPoints = async () => {
			try {
				const { data } = await axios.get('https://teplye-denki.ru/api/points/get-all')
				setPoints(data)
			} catch (error) {
				console.log(error);
			}
		}
		const getGallery = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/gallery/get-all'
				)
				setGallery(data)
				console.log(data)
			} catch (error) {
				console.log(error);
			}
		}
		const getAllCatalogs = async () => {
			try {
				const { data } = await axios.get(
					'https://teplye-denki.ru/api/catalog/get-all'
				)
				setCatalog(data)
				console.log(data)
			} catch (error) {
				console.log(error)
			}
		}
		getAllApps()
		getAllNews()
		getAllPoints()
		getGallery()
		getAllServices()
		getAllCatalogs()
	}, [])


	const handleClick = event => {
		const rect = event.currentTarget.getBoundingClientRect() // Получаем размеры и позицию дива
		const left = event.clientX - rect.left // X относительно дива
		const top = event.clientY - rect.top // Y относительно дива
		setNewPoints([...newPoints, { id: newPoints.length ? newPoints[newPoints.length - 1]["id"] + 1 : 1, left: left, top: top, type: true }])
	}

    const handleLogin = async () => {
			try {
				const response = await axios.post('https://teplye-denki.ru/api/auth/login', {
					username,
					password,
				})
				const token = response.data.token
                if(token) {
                    localStorage.setItem('authToken', token)
                    setLogined(true)
                    alert('Вы вошли в систему!')
                }
			} catch (error) {
				alert(error.response.data.message)
			}
		}


  return (
		<div className={classes.admin}>
			{!logined ? (
				<div className={classes.login}>
					<form
						className={classes.form}
						onSubmit={evn => {
							evn.preventDefault()
							handleLogin()
						}}
					>
						<input
							placeholder='Логин'
							required
							type='text'
							onChange={evn => setUsername(evn.target.value)}
						/>
						<input
							placeholder='Пароль'
							required
							type='password'
							onChange={evn => setPassword(evn.target.value)}
						/>
						<button className={classes.button} type='submit'>
							Войти
						</button>
					</form>
				</div>
			) : (
				<div className={classes.adminka}>
					<p>Добро пожаловать админ !</p>
					<div className={classes.zayavki}>
						<table className={classes.styled_table}>
							<thead>
								<tr>
									<th>N</th>
									<th>Имя</th>
									<th>Телефон</th>
									<th>Сообщение</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{apps.map(el => {
									return (
										<tr>
											<td>{el.id}</td>
											<td>{el.name}</td>
											<td>{el.phone}</td>
											<td>{el.message}</td>
											<td
												style={{ color: 'red', cursor: 'pointer' }}
												onClick={() => deleteApp(el.id)}
											>
												Удалить
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							gap: '15px',
							alignItems: 'center',
						}}
					>
						<div>
							{newPoints.map(el => {
								return (
									<Point
										id={el.id}
										key={el.id}
										type={el.type}
										onSave={text => addPoint(el.left, el.top, text, el.id)}
										deletePoint={id => deletePoint(id, el.type)}
									/>
								)
							})}
						</div>
						<div className={classes.map_panel}>
							<div
								className={classes.map_p}
								onClick={evn => {
									!big ? handleClick(evn) : setBig(!big)
								}}
								style={big ? { transform: 'scale(1.5)', top: '50px' } : null}
							>
								{points.map(el => {
									return (
										<div className={classes.pon}>
											<div
												className={classes.point}
												style={{ left: el.left, top: el.top }}
												key={el.id}
											>
												<label htmlFor=''>{el.text}</label>
												<span>{el.id}</span>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20'
													height='20'
													fill='green'
													class='bi bi-geo-alt-fill'
													viewBox='0 0 16 16'
												>
													<path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6' />
												</svg>
											</div>
										</div>
									)
								})}
								{newPoints.map(el => {
									return (
										<div className={classes.pon}>
											<div
												className={classes.point}
												style={{ left: el.left, top: el.top }}
												key={el.id}
											>
												<span>{el.id}</span>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20'
													height='20'
													fill='green'
													class='bi bi-geo-alt-fill'
													viewBox='0 0 16 16'
												>
													<path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6' />
												</svg>
											</div>
										</div>
									)
								})}
								<img src={map} alt='' width={'100%'} />
							</div>
							<button className={classes.btn} onClick={() => setBig(!big)}>
								Приблизить карту
							</button>
						</div>
						<div>
							{points.map(el => {
								return (
									<Point
										id={el.id}
										key={el.id}
										type={el.type}
										deletePoint={id => deletePoint(id, el.type)}
									/>
								)
							})}
						</div>
					</div>
					<div>
						<p className={classes.header}>Фотогалерея</p>
						<label className={classes.input_file}>
							<input
								type='file'
								name='file'
								onChange={e => {
									setGalleryImages([...galleryImages, e.target.files[0]])
								}}
							/>
							<span className={classes.input_file_text}>Добавить картинку</span>
							<span className={classes.input_file_btn}>Выберите файл</span>
						</label>
						<div>
							<div className={classes.added}>
								{galleryImages.map(el => {
									return (
										<div style={{ position: 'relative' }}>
											<button
												className={classes.del}
												onClick={() =>
													setGalleryImages(galleryImages.filter(e => e !== el))
												}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													fill='currentColor'
													class='bi bi-trash-fill'
													viewBox='0 0 16 16'
												>
													<path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0' />
												</svg>
											</button>
											<img src={URL.createObjectURL(el)} width={'250px'} />
										</div>
									)
								})}
							</div>
							{galleryImages.length ? (
								<button className={classes.button} onClick={addImage}>
									Сохранить
								</button>
							) : null}
						</div>
						<div className={classes.gallery}>
							{gallery.map(el => {
								return (
									<div key={el.id}>
										<button
											className={classes.del}
											onClick={() => deleteImage(el.id)}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												fill='currentColor'
												class='bi bi-trash-fill'
												viewBox='0 0 16 16'
											>
												<path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0' />
											</svg>
										</button>
										<img
											src={`https://teplye-denki.ru${el.img}`}
											alt=''
											width={'100%'}
										/>
									</div>
								)
							})}
						</div>
					</div>
					<div>
						<p className={classes.header}>Новости</p>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<button
								className={classes.button}
								onClick={() => setShowArea(!showArea)}
							>
								+ Написать новость
							</button>
						</div>
						{showArea && (
							<form
								className={classes.area}
								onSubmit={env => {
									env.preventDefault()
									addNew()
								}}
							>
								<label className={classes.input_file}>
									<input
										type='file'
										name='file'
										onChange={e => {
											setImg(e.target.files[0])
										}}
									/>
									<span className={classes.input_file_text}>
										Добавить картинку
									</span>
									<span className={classes.input_file_btn}>Выберите файл</span>
								</label>
								{img ? (
									<img src={URL.createObjectURL(img)} height={'350px'} />
								) : null}
								<input
									type='text'
									className={classes.inpt}
									placeholder='Заголовок'
									onChange={env => setHeader(env.target.value)}
									required
								/>
								<textarea
									type='text'
									className={classes.inpt}
									placeholder='Текст'
									onChange={env => setText(env.target.value)}
									required
								/>
								<button className={classes.button} type='submit'>
									Сохранить
								</button>
							</form>
						)}
						<br />
						<div className={classes.n}>
							{news.map(el => {
								return (
									<div style={{ position: 'relative' }}>
										<button
											className={classes.del}
											onClick={() => deleteNew(el.id)}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												fill='currentColor'
												class='bi bi-trash-fill'
												viewBox='0 0 16 16'
											>
												<path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0' />
											</svg>
										</button>
										<New
											key={el.id}
											header={el.header}
											image={el.headerImg}
											date={'10.05.2024'}
										/>
									</div>
								)
							})}
						</div>
						<div>
							<p className={classes.header}>Предоставляем услуги</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<button
									className={classes.button}
									onClick={() => setShowArea2(!showArea2)}
								>
									+ Добавить услугу
								</button>
							</div>
							{showArea2 && (
								<form
									className={classes.area}
									onSubmit={env => {
										env.preventDefault()
										addService()
									}}
								>
									<label className={classes.input_file}>
										<input
											type='file'
											name='file'
											onChange={e => {
												setImgService(e.target.files[0])
											}}
										/>
										<span className={classes.input_file_text}>
											Добавить картинку
										</span>
										<span className={classes.input_file_btn}>
											Выберите файл
										</span>
									</label>
									{imgService ? (
										<img
											src={URL.createObjectURL(imgService)}
											height={'350px'}
										/>
									) : null}
									<input
										type='text'
										className={classes.inpt}
										placeholder='Заголовок'
										onChange={env => setHeaderService(env.target.value)}
										required
									/>
									<textarea
										type='text'
										className={classes.inpt}
										placeholder='Текст'
										onChange={env => setTextService(env.target.value)}
										required
									/>
									<button className={classes.button} type='submit'>
										Сохранить
									</button>
								</form>
							)}
							<div className={classes.services}>
								{services.map(el => {
									return (
										<div key={el.id} style={{ position: 'relative' }}>
											<button
												className={classes.del}
												onClick={() => deleteService(el.id)}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													fill='currentColor'
													class='bi bi-trash-fill'
													viewBox='0 0 16 16'
												>
													<path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0' />
												</svg>
											</button>
											<Service
												header={el.header}
												text={el.text}
												img={el.headerImg}
											/>
										</div>
									)
								})}
							</div>
						</div>
						<div>
							<p className={classes.header}>
								Каталог пансионатов для пожилых в Москве
							</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<button
									className={classes.button}
									onClick={() => setShowArea3(!showArea3)}
								>
									+ Добавить пансионат
								</button>
							</div>
							{showArea3 && (
								<form
									className={classes.area}
									onSubmit={env => {
										env.preventDefault()
										addCatalog()
									}}
								>
									<label className={classes.input_file}>
										<input
											type='file'
											name='file'
											onChange={e => {
												setImgCatalog(e.target.files[0])
											}}
										/>
										<span className={classes.input_file_text}>
											Добавить картинку
										</span>
										<span className={classes.input_file_btn}>
											Выберите файл
										</span>
									</label>
									{imgCatalog ? (
										<img
											src={URL.createObjectURL(imgCatalog)}
											height={'350px'}
										/>
									) : null}
									<input
										type='text'
										className={classes.inpt}
										placeholder='Название'
										onChange={env => setHeaderCatalog(env.target.value)}
										required
									/>
									<input
										type='number'
										className={classes.inpt}
										placeholder='Цена за сутки'
										onChange={env => setPrice(env.target.value)}
										required
									/>
									<input
										type='text'
										className={classes.inpt}
										placeholder='Адрес'
										onChange={env => setAddress(env.target.value)}
										required
									/>
									<button className={classes.button} type='submit'>
										Сохранить
									</button>
								</form>
							)}
							<div className={classes.services}>
								{catalog.map(el => {
									return (
										<div key={el.id} style={{ position: 'relative' }}>
											<button
												className={classes.del}
												onClick={() => deleteCatalog(el.id)}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													fill='currentColor'
													class='bi bi-trash-fill'
													viewBox='0 0 16 16'
												>
													<path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0' />
												</svg>
											</button>
											<Card
												header={el.header}
												img={el.headerImg}
												price={el.price}
												address={el.address}
											/>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Admin