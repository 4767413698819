import React, { forwardRef } from 'react'
import classes from "./prices.module.css"
import Price from '../price/Price'

const Prices = forwardRef((props, ref) => {
  return (
		<div ref={ref}>
			<p className={classes.header}>
				Пансионаты для пожилых людей в Москве — Цены
			</p>
			<div className={classes.prices}>
				<Price
					header={'Базовый уход'}
					type={'Для самостоятельных пожилых людей'}
					pluses={[
						' Круглосуточный уход сиделки',
						' Функциональные кровати',
						' Противопролежневые матрасы',
						' Медицинский уход',
						' Регулярный антипролежневый массаж',
						' Возможность cоставления программы восстановления',
					]}
				/>
				<Price
					header={'Базовый уход'}
					type={'Для самостоятельных пожилых людей'}
					pluses={[
						' Круглосуточный уход сиделки',
						' Функциональные кровати',
						' Противопролежневые матрасы',
						' Медицинский уход',
						' Регулярный антипролежневый массаж',
						' Возможность cоставления программы восстановления',
					]}
				/>
				<Price
					header={'Базовый уход'}
					type={'Для самостоятельных пожилых людей'}
					pluses={[
						' Круглосуточный уход сиделки',
						' Функциональные кровати',
						' Противопролежневые матрасы',
						' Медицинский уход',
						' Регулярный антипролежневый массаж',
						' Возможность cоставления программы восстановления',
					]}
				/>
			</div>
		</div>
	)
});

export default Prices