import React, { useRef, useState } from 'react'
import HomeWrapper from '../../components/home-wrapper/HomeWrapper'
import HomeInfo from '../../components/home-info/HomeInfo'
import HomeQuestions from '../../components/home-questions/HomeQuestions'
import Steps from '../../components/steps/Steps'
import Catalog from '../../components/catalog/Catalog'
import News from '../../components/news/News'
import Prices from '../../components/prices/Prices'
import Services from '../../components/services/Services'
import Documents from '../../components/documents/Documents'
import Contact from '../../components/contact/Contact'
import NewPanel from '../../components/new-panel/NewPanel'
import Gallery from '../../components/gallery/Gallery'
import Phones from '../../components/phones/Phones'

const Home = ({servicesTarget, galleryTarget, pricesTarget, contactsTarget, housesRef}) => {
	const targetRef = useRef(null)
	const [id, setId] = useState(null)
	const [showPanel, setShowPanel] = useState(false)

	return (
		<div>
			{showPanel && (
				<NewPanel
					id={id}
					onClose={() => {
						setShowPanel(false)
						setId(null)
					}}
				/>
			)}
			<HomeWrapper
				scrollToTarget={() => {
					targetRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'nearest',
					})
				}}
			/>
			<div className='page-data'>
				<HomeInfo />
				<HomeQuestions />
				<div className='steps-panel' ref={targetRef}>
					<Steps
						text={
							'Позвонить и получить информацию о возможности заселения постояльца'
						}
						step={1}
					/>
					<Steps
						text={
							'Записаться у координатора на онлайн-посещение подходящего учреждения'
						}
						step={2}
					/>
					<Steps
						text={
							'Определиться с датой заселения и заключить договор на проживание'
						}
						step={3}
					/>
				</div>
				<div ref={housesRef}>
					<Catalog />
				</div>
				<Prices ref={pricesTarget} />
				<Services ref={servicesTarget} />
				<News
					openNew={id => {
						setId(id)
						setShowPanel(true)
					}}
				/>
				<Gallery ref={galleryTarget} />
				<Contact ref={contactsTarget} />
				<Documents />
			</div>
		</div>
	)
}

export default Home
