import React from 'react'
import classes from "./documents.module.css"
import Password from "../../img/passport.png"
import Med from '../../img/med.png'
import Snils from '../../img/snils.png'
import Vipiska from '../../img/vipiska.png'

const Documents = () => {
  return (
		<div>
			<p className={classes.header}>
				Какие документы нужно взять с собой при заселении
			</p>
			<div className={classes.info}>
				<p>
					Для заселения или оформления в пансионат, с собой нужно взять паспорт
					пожилого, ваш паспорт, медицинский полис ОМС, СНИЛС и выписку из
					больницы с рекомендация лечащего врача (если есть).
				</p>
				<div className={classes.documents}>
					<div>
						<img src={Password} alt='passport' />
						<p className={classes.doc_name}>Паспорт</p>
					</div>
					<div>
						<img src={Med} alt='passport' />
						<p className={classes.doc_name}>Мед. полис</p>
					</div>
					<div>
						<img src={Snils} alt='passport' />
						<p className={classes.doc_name}>СНИЛС</p>
					</div>
					<div>
						<img src={Vipiska} alt='passport' />
						<p className={classes.doc_name}>Выписка <br />(если есть)</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Documents