import React from 'react'
import classes from "./new.module.css"
import {ReactComponent as Date} from "../../icons/date.svg"

const New = ({header, date, image}) => {
  return (
		<div className={classes.block}>
			<img
				src={`https://teplye-denki.ru${image}`}
				alt='news image'
				className={classes.img}
			/>
			<p className={classes.header}>{header}</p>
			<div className={classes.footer}>
				<div className={classes.date}>
					<Date /> {date}
				</div>
				<span className={classes.more}>Подробнее</span>
			</div>
		</div>
	)
}

export default New