import React, { forwardRef, useEffect, useState } from 'react'
import classes from './catalog.module.css'
import Cards from '../cards/Cards'
import Phones from '../phones/Phones';

const Catalog = forwardRef((props, ref) => {

	return (
		<>
			<div className={classes.catalog}>
				<h2 className={classes.header} ref={ref}>
					Каталог пансионатов для пожилых в Москве
				</h2>
				<Cards />
			</div>
		</>
	)
});

export default Catalog
